import React from 'react';
import CarouselComp from '../components/inc/CarouselComp';

const Inicio = () => {
  return (
    <div>
        <CarouselComp/>
    </div>
  );
};

export default Inicio;