import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
    width: '500px',
    height: '500px'
};

const Alliance = {
    lat: -33.41954371650327,
    lng: -70.60077189990679
};/* 
const calle = {
  lat: -33.41957439548067,
  lng: -70.60102885857705
}; */
function Redireccion() {
  window.location.href = "https://www.google.cl/maps/dir//Alliance+Santiago+-+Chile+-+Av.+Hernando+de+Aguirre+162,+Providencia,+Regi%C3%B3n+Metropolitana/@-33.4195303,-70.6029606,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x9662cf5e13c00017:0x85830922e5e9641f!2m2!1d-70.6007719!2d-33.4195348!3e0"
}

function MyComponent() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCxiqQJ_4SPbYx_qtkO6CvK8e0sm7WMgzY"
  })

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={Alliance} zoom={17} >
     <Marker
        position={Alliance}
        onClick={Redireccion}
        clickable
      />
    </GoogleMap>
  ) : <></>
}

export default React.memo(MyComponent)