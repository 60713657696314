import React from 'react';
import MapComp from '../components/inc/MapComp';

const Contacto = () => {
  return (
    <div>
        <h2>¿Cómo Llegar?</h2>
        <div className='mapComponent'><MapComp/></div>
    </div>
  );
};

export default Contacto;