import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Inicio from './pages/Home';
import NavComp from './components/inc/NavComp';
import Nosotros from './pages/About';
import Contacto from './pages/Contact'
import Footer from './pages/Footer';
import { SiWhatsapp } from 'react-icons/si';
import CardComp from './components/inc/CardComp';

function whatsapp() {
  window.location.href = "https://wa.me/+56948999358?text=Hola%20quiero%20agendar%20una%20clase%20de%20prueba."
}

function App() {
  return (
    <div className='App'>
      <NavComp/>
      <section id="inicio">
        <Inicio/>
      </section>
      <section id="nosotros">
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="4" lg="5" xl="6" className='mx-auto mb-4'>
             <Nosotros/>
            </MDBCol>
            <MDBCol md="2" lg="4" xl="5" className='mx-auto mb-4' id='cardElement'>
              <CardComp/>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      <section id="contacto">
        <Contacto/>
      </section>
      <Footer/>
      <button class="whatsapp" type="button" onClick={whatsapp}>
        <SiWhatsapp/>
      </button>
    </div>
  );
}

export default App;