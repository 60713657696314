import React, { Component } from 'react';
import { Carousel } from "react-bootstrap";
class CarouselComp extends Component {
    render() {
        return (
            <div id='slider'>
                <Carousel>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="https://i.postimg.cc/Dz4GJx4q/MG-4276.jpg"
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="https://i.postimg.cc/3xcpxsN8/MG-4267.jpg"
                            alt="Second slide"
                            rounded
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <img
                            className="d-block w-100"
                            src="https://i.postimg.cc/RCdW0kpk/DSC-4351.jpg"
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>
        );
    }
}

export default CarouselComp;