import React, { Component } from 'react';
import { Navbar,Container,Nav } from 'react-bootstrap';
import { FaShareAlt } from 'react-icons/fa';
class NavComp extends Component {
    render() {
        return (
            <div id='navbar'>
                <Navbar collapseOnSelect expand="lg" style = {{color:'#FFB200'}} /* bg="dark" variant="dark" */ fixed="top">
                <Container>
                    <Navbar.Brand href="#inicio">Alliance</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#nosotros">Nosotros</Nav.Link>
                        <Nav.Link href="#contacto">Contacto</Nav.Link>
                        <Nav.Link href="#social"><FaShareAlt/></Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link href="https://alliancebjj.cl/login/index.html">Login/Register</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
                </Navbar>
            </div>
        );
    }
}

export default NavComp;