import React from 'react';

const Nosotros = () => {
  return (
    <div>
        <h2>Nuestra Historia</h2>
        <p>En los años 80, el maestro Romero "Jacaré" Cavalcanti y sus dos alumnos cinturón negro Fabio Gurgel (general) y Alexandre "gigi" Paiva siguieron caminos diferentes en busca del éxito en sus propias academias, sin embargo, en todos los campeonatos se encontraron y terminaron compitiendo entre sí, creando una rivalidad injusta debido a sus orígenes, por esta razón, en 1993 los tres tomaron la decisión de formar una alianza, Alliance en 2014, viendo que cada una de sus academias se estaba expandiendo y formando sucursales, decidieron crear una asociación para unificar el método de enseñanza e implementar los conocimientos de gestión que estaban teniendo gran éxito en manos del profesor Fabio Gurgel en la sede de São Paulo.</p>
        <p>En la actualidad, el equipo Alliance cuenta con más de 300 sucursales repartidas por todo el mundo, con una metodología de referencia y 12 veces campeón del mundo. lo que demuestra la fuerza, la dedicación y la unión de este equipo. entre los principales campeones están: <b>Sergio Moraes, Bruno Malfacine, Cobrinha, Leonardo Leite, leo Nogueira, Tarsis Humprhreys, Bernardo Faria, Michael Langhi, Marcelinho García, Lucas Lepri, Isaque Bahiense, Luana Alzuguir, Daniela Genovesi, Nicholas Meregali, Gabi Garcia, Monique Elias, Fernando Terere y Andressa Correa</b>.</p>
        <p>Y tú puedes formar parte de esta historia: al hacerte afiliado, tendrás a tu disposición los cursos con la metodología de Alliance y todos los aprendizajes de éxito en la gestión para tu academia que harán que tu academia sea realmente exitosa.</p>
    </div>
  );
};

export default Nosotros;